import pythonAxios from '../services/axiosConfigPython';

const apiCallWrapper =
  apiCall =>
  async (...args) => {
    try {
      const response = await apiCall(...args);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error(
          `Error: ${error.response.status} - ${error.response.data}`,
        );
        throw new Error(
          error.response.data.detail || 'An error occurred while fetching data',
        );
      } else {
        console.error(`Error: ${error.message}`);
        throw new Error('An error occurred while fetching data');
      }
    }
  };
// Organization fetchers
export const fetchOrganizations = async (projectId, skip = 0, limit = 100) => {
  const response = await pythonAxios.get(
    `/organizations/project/${projectId}`,
    {
      params: { skip, limit },
    },
  );
  return response.data;
};

export const createOrganization = async organizationData => {
  const response = await pythonAxios.post('/organizations/', {
    ...organizationData,
    parent_id: organizationData.parent_id || null,
  });
  return response.data;
};

export const updateOrganization = async (organizationId, organizationData) => {
  const response = await pythonAxios.put(
    `/organizations/${organizationId}`,
    organizationData,
  );
  return response.data;
};

export const deleteOrganization = async organizationId => {
  const response = await pythonAxios.delete(`/organizations/${organizationId}`);
  return response.data;
};

export const fetchOrganizationTree = async (includeExternal = false) => {
  const response = await pythonAxios.get('/organizations/tree', {
    params: { include_external: includeExternal },
  });
  return response.data;
};

export const fetchOrganizationSubtree = async organizationId => {
  const response = await pythonAxios.get(
    `/organizations/${organizationId}/subtree`,
  );
  return response.data;
};

export const syncCompaniesToOrganizations = async () => {
  const response = await pythonAxios.post('/organizations/sync-companies');
  return response.data;
};

export const fetchProjectOrganizationTree = async projectId => {
  const response = await pythonAxios.get(
    `/organizations/projects/${projectId}/organization-tree`,
  );
  return response.data;
};

export const fetchOrganizationTreeview = async projectId => {
  const response = await pythonAxios.get(
    `/organizations/projects/${projectId}/treeview`,
  );
  return response.data;
};

export const fetchOrganizationHierarchy = async organizationId => {
  const response = await pythonAxios.get(
    `/organizations/hierarchy/${organizationId}`,
  );
  return response.data;
};

export const updateOrganizationOrder = async (projectId, orderData) => {
  const response = await pythonAxios.put(
    `/organizations/projects/${projectId}/update-order`,
    orderData,
  );
  return response.data;
};

export const api = {
  fetchOrganizations: apiCallWrapper(fetchOrganizations),
  createOrganization: apiCallWrapper(createOrganization),
  updateOrganization: apiCallWrapper(updateOrganization),
  deleteOrganization: apiCallWrapper(deleteOrganization),
  fetchOrganizationTree: apiCallWrapper(fetchOrganizationTree),
  fetchOrganizationSubtree: apiCallWrapper(fetchOrganizationSubtree),
  syncCompaniesToOrganizations: apiCallWrapper(syncCompaniesToOrganizations),
  fetchProjectOrganizationTree: apiCallWrapper(fetchProjectOrganizationTree),
  fetchOrganizationTreeview: apiCallWrapper(fetchOrganizationTreeview),
  fetchOrganizationHierarchy: apiCallWrapper(fetchOrganizationHierarchy),
  updateOrganizationOrder: apiCallWrapper(updateOrganizationOrder),
};
