import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';

import {
  createAccountCompany,
  deleteAccountCompany,
  fetchAccountCompanies,
  fetchAccounts,
  fetchCompanies,
} from '../../api/fetchers';
import ConfirmationDialog from '../ui-modals/ConfirmationDialog';
import Toast from '../ui-modals/ToastMessage';

const AdminAccountCompanyPanel = () => {
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [accountCompanies, setAccountCompanies] = useState([]);
  const [selectedAccountCompany, setSelectedAccountCompany] = useState(null);
  const [newAccountCompany, setNewAccountCompany] = useState({
    account_id: '',
    company_id: '',
  });
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    content: '',
    accountCompanyId: null,
    accountId: null,
    companyId: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [accountCompaniesData, accountsData, companiesData] =
          await Promise.all([
            fetchAccountCompanies(),
            fetchAccounts(),
            fetchCompanies(0, 1000),
          ]);
        setAccountCompanies(accountCompaniesData);
        setAccounts(accountsData);
        setCompanies(companiesData);
      } catch (error) {
        console.error('Error loading data:', error);
        setToast({
          open: true,
          message: 'Error loading data. Please try again.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };
    loadInitialData();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewAccountCompany(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setSelectedAccountCompany(null);
    setNewAccountCompany({
      account_id: '',
      company_id: '',
    });
  };

  const handleAddOrUpdateAccountCompany = async () => {
    try {
      if (selectedAccountCompany) {
        // Update Logic (If required)
        // const updatedAccountCompany = await updateAccountCompany(selectedAccountCompany.id, newAccountCompany);
        // setAccountCompanies(accountCompanies.map(ac => ac.id === updatedAccountCompany.id ? updatedAccountCompany : ac));
        setToast({
          open: true,
          message: 'Account-Company relation updated successfully!',
          severity: 'success',
        });
      } else {
        const createdAccountCompany =
          await createAccountCompany(newAccountCompany);
        setAccountCompanies([...accountCompanies, createdAccountCompany]);
        setToast({
          open: true,
          message: 'Account-Company relation added successfully!',
          severity: 'success',
        });
      }
      resetForm();
    } catch (error) {
      console.error('Error saving account-company relation:', error);
      setToast({
        open: true,
        message: 'Error saving account-company relation. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleDeleteAccountCompany = async () => {
    try {
      await deleteAccountCompany(
        confirmDialog.accountId,
        confirmDialog.companyId,
      );
      setAccountCompanies(
        accountCompanies.filter(
          ac =>
            !(
              ac.account_id === confirmDialog.accountId &&
              ac.company_id === confirmDialog.companyId
            ),
        ),
      );
      setToast({
        open: true,
        message: 'Account-Company relation deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting account-company relation:', error);
      setToast({
        open: true,
        message: 'Error deleting account-company relation. Please try again.',
        severity: 'error',
      });
    } finally {
      setConfirmDialog({ ...confirmDialog, open: false });
    }
  };

  const handleDeleteClick = accountCompanyId => {
    const accountCompanyToDelete = accountCompanies.find(
      ac => ac.id === accountCompanyId,
    );
    setConfirmDialog({
      open: true,
      title: 'Confirm Deletion',
      content: `Are you sure you want to delete the relation between "${accountCompanyToDelete.account_name}" and "${accountCompanyToDelete.company_name}"?`,
      accountId: accountCompanyToDelete.account_id,
      companyId: accountCompanyToDelete.company_id,
    });
  };

  const handleCancelDelete = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'account_name', headerName: 'Account Name', width: 200 },
    { field: 'company_name', headerName: 'Company Name', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: params => (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Manage Account-Company Relations
      </Typography>
      <Box className="h-[50vh] w-full mt-2" sx={{ flexGrow: 1, mb: 2 }}>
        <DataGridPremium
          rows={accountCompanies}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Account</InputLabel>
              <Select
                name="account_id"
                value={newAccountCompany.account_id}
                onChange={handleInputChange}
                required
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                }}
              >
                {accounts.map(account => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Company</InputLabel>
              <Select
                name="company_id"
                value={newAccountCompany.company_id}
                onChange={handleInputChange}
                required
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                }}
              >
                {companies.map(company => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {selectedAccountCompany ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddOrUpdateAccountCompany}
                >
                  Update Relation
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetForm}
                >
                  Cancel Edit
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddOrUpdateAccountCompany}
              >
                Add Account-Company Relationship
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={() => setToast({ ...toast, open: false })}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        content={confirmDialog.content}
        onConfirm={handleDeleteAccountCompany}
        onCancel={handleCancelDelete}
      />
    </Box>
  );
};

export default AdminAccountCompanyPanel;
