import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { fetchUserProjects } from '../../api/fetchers';
import ThemeContext from '../../contexts/ThemeContext';

const ProjectSelector = ({
  userId,
  onProjectSelect,
  selectedProjectId,
  projects: providedProjects,
  useProvidedProjects = false,
}) => {
  const [projects, setProjects] = useState([]);
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        if (!useProvidedProjects) {
          const userProjects = await fetchUserProjects(userId);
          setProjects(userProjects);

          if (userProjects.length > 0 && !selectedProjectId) {
            const defaultProject = userProjects[0];
            onProjectSelect(defaultProject.id);
          }
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
        setProjects([]);
      }
    };

    if (userId && !useProvidedProjects) {
      fetchProjects();
    }
  }, [userId, onProjectSelect, selectedProjectId, useProvidedProjects]);

  useEffect(() => {
    if (useProvidedProjects && providedProjects) {
      setProjects(providedProjects);
      if (providedProjects.length > 0 && !selectedProjectId) {
        onProjectSelect(providedProjects[0].id);
      }
    }
  }, [
    providedProjects,
    useProvidedProjects,
    selectedProjectId,
    onProjectSelect,
  ]);

  const handleProjectChange = event => {
    const projectId = event.target.value;
    onProjectSelect(projectId);
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300, // Set maximum height for dropdown
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: isDarkMode ? '#2d2d2d' : '#f1f1f1',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: isDarkMode ? '#666' : '#888',
          borderRadius: '4px',
          '&:hover': {
            background: isDarkMode ? '#888' : '#666',
          },
        },
      },
    },
    MenuListProps: {
      style: {
        padding: '4px 0',
      },
    },
  };

  const sortedProjects = useMemo(() => {
    return [...projects].sort((a, b) => a.name.localeCompare(b.name));
  }, [projects]);

  return (
    <FormControl
      fullWidth
      margin="normal"
      sx={{
        minWidth: 200,
        '& .MuiInputBase-root': {
          borderRadius: '8px',
        },
        '& .MuiSelect-icon': {
          right: 8,
          color: isDarkMode ? 'var(--border-light)' : 'inherit',
        },
      }}
    >
      <Typography
        variant="caption"
        sx={{
          marginBottom: '4px',
          color: isDarkMode ? 'var(--border-light)' : 'textPrimary',
        }}
      >
        Select Project
      </Typography>
      <Select
        value={selectedProjectId || ''}
        onChange={handleProjectChange}
        MenuProps={menuProps}
        sx={{
          '& .MuiSelect-select': {
            padding: '8px 14px',
            backgroundColor: isDarkMode
              ? 'var(--bg-secondary-dark)'
              : 'var(--bg-secondary-light)',
            color: isDarkMode ? '#fff' : '#000',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
            borderColor: isDarkMode ? '#ffffff' : 'var(--border-dark)',
          },
        }}
      >
        {sortedProjects.map(project => (
          <MenuItem
            key={project.id}
            value={project.id}
            sx={{
              fontSize: '0.875rem',
              minHeight: '32px',
            }}
          >
            {project.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProjectSelector;
