export const CURRENT_VERSION = '0.9.9.3';
export const APP_NAME = 'Tandem Investor Platform';
export const COPYRIGHT_MSG =
  '© 2024 Skala & Tandem Group. All rights reserved.';

export const RELEASE_NOTES = [
  {
    version: '0.9.9.3',
    description:
      'Fixed issue with account-company relationship management in admin panel.',
    date: '2024-11-14',
  },
  {
    version: '0.9.9.2',
    description:
      'Updated organization chart admin panel to allow new parent organizations.',
    date: '2024-11-13',
  },
  {
    version: '0.9.9.1',
    description:
      'Improved project selection menu and organization chart administration functionality.',
    date: '2024-10-30',
  },
  {
    version: '0.9.9',
    description:
      'Initial production release: Enhanced stability and improved user experience.',
    date: '2024-10-29',
  },
  {
    version: '0.9.8.5',
    description:
      'Introduced organizational hierarchy visualization of overall corporate structure for selected project.',
    date: '2024-10-24',
  },
  {
    version: '0.9.8.2',
    description:
      'Project dashboard chart visual improvements and data compatability fixes.',
    date: '2024-10-16',
  },
  {
    version: '0.9.8',
    description:
      'Official beta release: Introduced new dashboard functionality and user experience improvements.',
    date: '2024-10-15',
  },
  {
    version: '0.9.7.2',
    description:
      'Updates project dashboard display of dates and calculations of Land Development projects.',
    date: '2024-10-08',
  },
  {
    version: '0.9.7',
    description:
      'Beta pre-release (internal): Improvements to overall UI/UX, dashboards, report and upload functionality.',
    date: '2024-10-08',
  },
  {
    version: '0.9.5',
    description:
      'Alpha release with additional bugfixes regarding display of dates on project dashboard charts.',
    date: '2024-08-27',
  },
];

// New labels
export const ABOUT_MODAL_TITLE = 'About';
export const SYSTEM_INFO_COPY_TOOLTIP = 'Copy system info';
export const SYSTEM_INFO_COPIED_TOOLTIP = 'Copied!';
export const CLOSE_BUTTON_TEXT = 'Close';
export const DOCUMENTATION_LINK_TEXT = 'Documentation';
export const RELEASE_NOTES_TITLE = 'Release Notes:';
